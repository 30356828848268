import {authorize, getAuthorizedAccounts} from "../helpers/auth";

let web3;

$(document.body).ready(() => {
  $(".js-test").click(() => {
    authorize({
      force: true,
    }).then((res) => {
      web3 = res;
      return getAuthorizedAccounts();
    }).then((accounts) => {
      console.log(accounts);
      window.onWalletConnected && window.onWalletConnected(web3, accounts);
    });
  });
})