import {queryStringToObject} from "./tools/url";

const query = queryStringToObject(window.location.search.substring(1));
const isDebug = !!query["debug"];
const clientToken = window.localStorage["client_token"] || makeClientToken();
const splitGroup = parseInt(query["split_group"]
  || window.localStorage["split_group"]
  || (Math.floor(Math.random() * 10) + 1)
);

window.localStorage["client_token"] = clientToken;
window.localStorage["split_group"] = splitGroup;

const config = {
  loadedUrl: new URL(window.location.href),
  query: query,
  isDebug: isDebug,
  isMobile: /iphone|ipad|android/i.test(window.navigator.userAgent),
  isMetaMask: /metamask/i.test(window.navigator.userAgent),
  clientToken: clientToken,
  userSplitGroup: splitGroup,
  contract: {
    address: {
      mainnet: "0xE8078B5198E572Be8D8D412511d48b7D0f5E9a1c",
      goerli: "0x7E3BA0bCD192155ac7b9E51613C639e4e026d2dD",
    },
    abiPath: "./R2d2V0Extended.abi.json",
  },
  services: {
    infura: {
      key: "5871ff536f3d43e59131d71f88c2b0b5",
    },
    fortmatic: {
      // key: "pk_test_41AA52F7E30B7147", // test
      key: "pk_live_93AC93876E750FF6", // production
    },
    coinbase: {
      appName: "tooncoin",
      appLogoUrl: "", // todo
    }
  },
};

export default config;

function makeClientToken() {
  let result = "";
  const map = "abcdef0123456789";
  for (let i = 0; i < 32; i++) {
    result += map.charAt(Math.floor(Math.random() * map.length));
  }

  return "b:u:" + result;
}