import config from "../config";
import Web3 from "web3";
import detectEthereumProvider from "@metamask/detect-provider";
import WalletConnectProvider from "@walletconnect/web3-provider";
import {getMetaMaskDeeplink, isMetaMaskInstalled} from "./metamask";
import Fortmatic from "fortmatic";
import CoinbaseWalletSDK from "@coinbase/wallet-sdk"

const svgNs = "http://www.w3.org/2000/svg";

let web3 = null;

function getMetaMaskProvider() {
  return detectEthereumProvider({mustBeMetaMask: true});
}

function getWalletConnectProvider() {
  const provider = new WalletConnectProvider({
    infuraId: config.services.infura.key,
    // chainId: 5, // goerli
  });

  return provider.enable().then(() => {
    return provider;
  });
}

function getFortmaticProvider() {
  const fortmatic = new Fortmatic(config.services.fortmatic.key);
  const provider = fortmatic.getProvider();

  return provider.enable().then(() => {
    return provider;
  })
}

function getCoinbaseProvider() {
  const coinbaseSdk = new CoinbaseWalletSDK({
    appName: config.services.coinbase.appName,
    appLogoUrl: config.services.coinbase.appLogoUrl,
    darkMode: false,
  });

  const provider = coinbaseSdk.makeWeb3Provider();

  return provider.enable().then(() => {
    return provider;
  })
}

export function getAuthorizedAccounts() {
  // метамаск не даст аккаунт через web3.eth.getAccounts
  // пока браузерный экстеншен просит пароль для разблокировки кошелька
  // поэтому запрашиваем аккаунты через request метод, чтобы метамаск отобразил юзеру окно с вводом пароля
  if (web3.eth.currentProvider.isMetaMask) {
    return web3.eth.currentProvider.request({method: "eth_requestAccounts"});
  } else {
    return web3.eth.getAccounts();
  }
}

export function authorize(params) {
  const _options = Object.assign({
    force: false,
    metaMaskDeeplinkUrl: null,
  }, params);

  if (_options.force) {
    window.localStorage.removeItem("WALLETCONNECT_DEEPLINK_CHOICE");
    window.localStorage.removeItem("walletconnect");

    if (web3 !== null) {
      try {
        web3.eth.currentProvider.disconnect();
      } catch (e) {
        console.error(e);
      }

      web3 = null;
    }
  }

  if (web3 !== null) {
    return Promise.resolve(web3);
  } else {
    return authorizeFlow(_options);
  }
}

function authorizeFlow(params) {
  const popup = createPopup();
  const closePopup = () => popup.remove();

  document.body.appendChild(popup);

  return new Promise((resolve, reject) => {
    popup.querySelector(".popup__close").addEventListener("click", () => {
      closePopup();
      reject("User cancel authorization");
    });

    popup.querySelector(".js-connect-mm").addEventListener("click", () => {
      if (isMetaMaskInstalled()) {
        getMetaMaskProvider().then((provider) => {
          closePopup();
          resolve(provider);
        });
      } else {
        if (config.isMobile) {
          window.location.href = getMetaMaskDeeplink(params.metaMaskDeeplinkUrl || window.location.href);
        } else {
          window.open("https://metamask.io/");
        }
      }
    });

    popup.querySelector(".js-connect-wc").addEventListener("click", () => {
      getWalletConnectProvider().then((provider) => {
        closePopup();
        resolve(provider);
      });
    });

    popup.querySelector(".js-connect-fortmatic").addEventListener("click", () => {
      getFortmaticProvider().then((provider) => {
        closePopup();
        resolve(provider);
      });
    });

    popup.querySelector(".js-connect-coinbase").addEventListener("click", () => {
      getCoinbaseProvider().then((provider) => {
        closePopup();
        resolve(provider);
      });
    });
  }).then((res) => {
    return web3 = new Web3(res);
  });
}

function createPopup() {
  const popupContainer = document.createElement("div");
  popupContainer.classList.add("popup-container", "popup-container_wallet");

  const popup = document.createElement("div");
  popup.classList.add("popup");
  popupContainer.appendChild(popup);

  const buttonsContainer = document.createElement("div");
  buttonsContainer.classList.add("popup__btns-container");
  popup.appendChild(buttonsContainer);

  const title = document.createElement("h3");
  title.classList.add("popup__title");
  title.innerText = "choose one:";
  buttonsContainer.appendChild(title);

  const metaMaskButton = document.createElement("button");
  metaMaskButton.classList.add("popup__btn", "js-connect-mm");
  buttonsContainer.appendChild(metaMaskButton);

  const metaMaskButtonImage = document.createElement("img");
  metaMaskButtonImage.src = "/img/seeklogo.png";
  metaMaskButtonImage.alt = "";
  metaMaskButton.appendChild(metaMaskButtonImage);

  const metaMaskButtonTitle = document.createElement("h4");
  metaMaskButtonTitle.innerText = "MetaMask";
  metaMaskButton.appendChild(metaMaskButtonTitle);

  const metaMaskButtonText = document.createElement("p");
  metaMaskButtonText.innerText = "Connect to your MetaMask Wallet";
  metaMaskButton.appendChild(metaMaskButtonText);

  // --

  const walletConnectButton = document.createElement("button");
  walletConnectButton.classList.add("popup__btn", "js-connect-wc");
  buttonsContainer.appendChild(walletConnectButton);

  const walletConnectButtonImage = document.createElement("img");
  walletConnectButtonImage.src = "/img/walletconnect.png";
  walletConnectButtonImage.alt = "";
  walletConnectButton.appendChild(walletConnectButtonImage);

  const walletConnectButtonTitle = document.createElement("h4");
  walletConnectButtonTitle.innerText = "WalletConnect";
  walletConnectButton.appendChild(walletConnectButtonTitle);

  const walletConnectButtonText = document.createElement("p");
  walletConnectButtonText.innerText = "Use WalletConnect to connect";
  walletConnectButton.appendChild(walletConnectButtonText);

  // --

  const fortmaticButton = document.createElement("button");
  fortmaticButton.classList.add("popup__btn", "js-connect-fortmatic");
  buttonsContainer.appendChild(fortmaticButton);

  const fortmaticButtonImage = document.createElement("img");
  fortmaticButtonImage.src = "/img/fortmatic.png";
  fortmaticButtonImage.alt = "";
  fortmaticButton.appendChild(fortmaticButtonImage);

  const fortmaticButtonTitle = document.createElement("h4");
  fortmaticButtonTitle.innerText = "Fortmatic";
  fortmaticButton.appendChild(fortmaticButtonTitle);

  const fortmaticButtonText = document.createElement("p");
  fortmaticButtonText.innerText = "Use Fortmatic to connect";
  fortmaticButton.appendChild(fortmaticButtonText);

  // --

  const coinbaseButton = document.createElement("button");
  coinbaseButton.classList.add("popup__btn", "js-connect-coinbase");
  buttonsContainer.appendChild(coinbaseButton);

  const coinbaseButtonImage = document.createElement("img");
  coinbaseButtonImage.src = "/img/coinbase.png";
  coinbaseButtonImage.alt = "";
  coinbaseButton.appendChild(coinbaseButtonImage);

  const coinbaseButtonTitle = document.createElement("h4");
  coinbaseButtonTitle.innerText = "Coinbase";
  coinbaseButton.appendChild(coinbaseButtonTitle);

  const coinbaseButtonText = document.createElement("p");
  coinbaseButtonText.innerText = "Use Coinbase to connect";
  coinbaseButton.appendChild(coinbaseButtonText);

  // --

  const closeButton = document.createElement("button");
  closeButton.classList.add("popup__close");
  popup.appendChild(closeButton);

  const closeButtonSvg = document.createElementNS(svgNs, "svg");
  closeButtonSvg.setAttributeNS(null, "viewBox", "0 0 320 512");
  closeButton.appendChild(closeButtonSvg);

  const closeButtonSvgPath = document.createElementNS(svgNs, "path");
  closeButtonSvgPath.setAttributeNS(null, "d", "M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z");
  closeButtonSvg.appendChild(closeButtonSvgPath);

  return popupContainer;
}